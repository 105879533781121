.header {
	font-size: 1.25em;
	margin-top: 2.5em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: 1000px) {
		margin-top: 1.2em;
	}
}

.text {
	font-size: 0.9em;
	@media screen and (max-width: 1000px) {
		font-size: 0.85em;
	}
	@media screen and (max-width: 500px) {
		font-size: 0.7em;
	}
}

.spacing {
	margin-top: 1.7em;
	@media screen and (max-width: 1000px) {
		margin-top: 0.8em;
	}
	width: 100%;
}

.registerRow {
	flex-direction: row;
	margin: 0 2em;
	@media screen and (max-width: 1000px) {
		flex-direction: column;
	}
}

.registerRow > a {
	margin-left: 2em;
	@media screen and (max-width: 1000px) {
		margin-left: 0em;
	}
}

.link {
	color: #3786fb;
	text-decoration: underline;
}

.maxWidth1200 {
	max-width: 1200px;
	width: 100%;
}
.maxWidth1000 {
	max-width: 1000px;
	width: 100%;
}
