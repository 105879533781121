@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-Thin-webfont.woff2") format("woff2");
	font-weight: 100;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-ThinItalic-webfont.woff2")
		format("woff2");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-ExtraLight-webfont.woff2")
		format("woff2");
	font-weight: 200;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-ExtraLightItalic-webfont.woff2")
		format("woff2");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-Light-webfont.woff2") format("woff2");
	font-weight: 300;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-LightItalic-webfont.woff2")
		format("woff2");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-Regular-webfont.woff2") format("woff2");
	font-weight: 400;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-RegularItalic-webfont.woff2")
		format("woff2");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-Medium-webfont.woff2") format("woff2");
	font-weight: 500;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-MediumItalic-webfont.woff2")
		format("woff2");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-Bold-webfont.woff2") format("woff2");
	font-weight: 600;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-BoldItalic-webfont.woff2")
		format("woff2");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-Heavy-webfont.woff2") format("woff2");
	font-weight: 800;
}

@font-face {
	font-family: Overlook;
	src: url("https://cdn-eoy.eventric.com/fonts/ATCOverlook-HeavyItalic-webfont.woff2")
		format("woff2");
	font-weight: 800;
	font-style: italic;
}

* {
	box-sizing: border-box;
	font-family: Overlook, Arial, sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
	word-break: break-word;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

body,
html,
#app,
#root,
.App,
#fullpage {
	height: 100%;
}

body {
	font-family: Overlook, Arial, sans-serif;
	font-weight: 300;
	margin: 0;
	text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
}
a,
a:visited {
	color: skyblue;
	text-decoration: none;
}

b {
	letter-spacing: 0.06em;
}

.App {
	color: white;
	text-align: center;
}

.flex {
	display: flex;
}
.center {
	align-items: center;
	justify-content: center;
}

.bold {
	font-weight: 700;
}

.redColor {
	color: #ff5151;
}

.yellowColor {
	color: #f39c12;
}

/* spinner */

.loading {
	background-image: url("https://cdn-eoy.eventric.com/img/bg-dark.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
@keyframes spinner {
	0% {
		transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
	}
	40% {
		transform: scaleY(0.4);
	}
	100% {
		transform: scaleY(0.4);
	}
}

/* side navigation */

#fp-nav {
	background-color: rgba(0, 0, 0, 0.5) !important;
	border-radius: 20px !important;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: #fff !important;
}

/* each section */

.section {
	align-items: center;
	display: flex;
	flex-direction: column;
	font-size: calc(10px + 2vmin);
	justify-content: center;
	min-height: 100%;
	padding: 0px 50px;
	position: relative;
}
.one {
	background-image: url("https://cdn-eoy.eventric.com/img/bg-dark.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.two {
	background-color: #16a085;
}
.three,
.four {
	background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
		url("https://cdn-eoy.eventric.com/img/kwest_bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.five {
	background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
		url("https://cdn-eoy.eventric.com/img/centerstaging_bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.six {
	background-color: #f39c12;
}
.seven {
	background-color: #16a085;
}
.eight {
	background-color: #393696;
}
.nine {
	background-color: #22a6b3;
}
.ten {
	background-color: #8e44ad;
}
.eleven {
	background-color: #3498db;
}
.twelve {
	background-color: #393696;
	background-size: 16em;
}
.thirteen {
	background-image: url("https://cdn-eoy.eventric.com/img/bg-dark.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.fourteen {
	background-image: url("https://cdn-eoy.eventric.com/img/ThankYouBG.jpg");
	background-size: 16em;
}
/* intro page down arrow */

.arrow {
	animation: bounce 0.7s infinite alternate;
	transition: stroke-width 0.2s ease, width 0.2s ease;
}
.arrow:hover {
	stroke-width: 5;
	width: 40px;
}
@keyframes bounce {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(0, 5px, 0);
	}
}

/* eventric social links */

.socialLink {
	transition: fill 0.2s ease-in-out;
}
.socialLink:hover {
	fill: rgba(255, 255, 255, 1);
}
.shareDownload,
.shareNative,
.shareLinkedIn,
.shareFacebook,
.shareTwitter {
	transition: background-color 0.2s ease-in-out;
	text-shadow: none;
}
.shareDownload {
	color: white !important;
	background-color: rgb(54, 68, 66);
}
.shareDownload:hover {
	background-color: rgb(77, 90, 88);
}
.shareLinkedIn {
	color: white !important;
	background-color: #0077b5;
}
.shareLinkedIn:hover {
	background-color: rgb(22, 152, 221);
}
.shareNative {
	color: white !important;
	background-color: rgb(35, 185, 68);
}
.shareNative:hover {
	background-color: rgb(47, 224, 86);
}
.shareFacebook {
	background-color: rgb(32, 47, 88);
}
.shareFacebook:hover {
	background-color: rgb(45, 69, 134);
}
.shareTwitter {
	background-color: rgb(27, 111, 185);
}
.shareTwitter:hover {
	background-color: rgb(28, 141, 239);
}

/* tagged locations text */

.taggedText {
	transform: rotate(-3deg);
}
.taggedTextSpan {
	background-color: #000;
	box-shadow: 12px -10px 20px rgba(0, 0, 0, 0.19), 8px -6px 6px rgba(0, 0, 0, 0.23);
	font-size: calc(30px + (82 - 30) * ((100vw - 300px) / (1600 - 300)));
	display: inline;
	padding: 0.1rem 0.3rem;
}

/* bus icon */

.busIcon {
	animation: drive 2s ease-in 0.7s forwards;
	stroke-width: 1px;
}
.wheel {
	animation: draw 0.5s linear 0.2s forwards;
	fill: transparent;
	stroke: #393696;
	stroke-dasharray: 50;
	stroke-dashoffset: 50;
}
.window {
	fill: #393696;
	stroke: #393696;
}
.busInner {
	fill: #393696;
}
.busOuter {
	animation: drawBusOuterFill 0.4s linear forwards;
	fill: transparent;
}
@keyframes drive {
	to {
		transform: translate3d(3000px, 0, 0);
	}
}
@keyframes drawBusOuterFill {
	to {
		fill: #000;
	}
}
@keyframes draw {
	to {
		stroke-dashoffset: 0;
	}
}

/*Donut chart slide 7 events */

.donutchart {
	border-radius: 50%;
	margin: 0 20px;
	transform: rotate(-90deg);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.donutchart-indicator {
	fill: transparent;
	stroke: #0074d9;
	stroke-dasharray: 158;
	stroke-dashoffset: 158;
	stroke-width: 50px;
}
.fullCircle {
	fill: transparent;
	stroke: #222;
	stroke-width: 50px;
}
.donut-loaded {
	animation: drawDonut 0.6s linear 0.2s forwards;
}
.donutchart-text {
	padding-top: 5px;
	fill: #fff;
}
.donutchart-text-val {
	font-size: 24px;
}
.donutchart-text-percent {
	font-size: 14px;
}
:root {
	--donut-offset: 158;
}
@keyframes drawDonut {
	to {
		stroke-dashoffset: var(--donut-offset);
	}
}

/* schedule items clock */

.clockHandMinute {
	animation: moveMinute 1s linear infinite;
	transform-origin: 49% 47%;
}

@keyframes moveMinute {
	from {
		transform: rotateZ(0deg);
	}
	to {
		transform: rotateZ(360deg);
	}
}
.clockHandHour {
	animation: moveHour 12s linear infinite;
	transform-origin: 49% 47%;
}

@keyframes moveHour {
	from {
		transform: rotateZ(0deg);
	}
	to {
		transform: rotateZ(360deg);
	}
}

/* make it rain */
@keyframes rain {
	to {
		top: 110%;
	}
}
