.layoutStyles {
	@media screen and (max-width: 1050px) {
		margin-left: -0.5em;
		margin-right: -0.5em;
	}
}
.lpsContainer {
	margin-top: 4em;
}
.contentSpacing {
	margin-top: 1em;
	@media screen and (max-width: 1050px) {
		margin-top: 0.8em;
	}
}
.tableSpacing {
	gap: 1em;
	flex-direction: column;
	@media screen and (max-height: 650px), screen and (width > 1000px) {
		gap: 5em;
		flex-direction: row;
		justify-content: space-between;
	}
}
.maxWidth1400 {
	max-width: 1400px;
}
.textLarge {
	font-size: 1.2em;
	@media screen and (max-width: 1000px) {
		font-size: 0.8em;
	}
}
.textSmall {
	font-size: 0.75em;
	@media screen and (max-width: 1000px) {
		font-size: 0.6em;
	}
}
.list {
	list-style: none;
	margin-left: 0;
	text-align: left;
}
.firstItem {
	margin-left: 20px;
}
.secondItem {
	margin-left: 60px;
}
.firstItem,
.secondItem {
	margin-top: 10px;
	@media screen and (max-width: 1050px) {
		margin-top: 0;
	}
}
.leftLps > div:not(:first-child),
.rightLps > div:not(:first-child) {
	margin-top: 1em;
}
.lpsSection {
	text-align: left;
	display: flex;
	flex-direction: column;
}
